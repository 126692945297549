.user-information div.ant-select-selection {
  border: none;
}

.user-information .ugly-field {
  border: 0.1rem solid lightgrey;
  display: flex;
  flex-flow: column;
  padding: 0.5rem;
  background: white;
  justify-content: flex-start;
  border-radius: 0.3rem;
  margin: 0.3rem;
}

.user-information .outer-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  background: #f4f6f8;
  padding: 2rem;
  margin: 0 0 1rem 0;
}

.user-information .flex-column {
  display: flex;
  flexflow: column;
}

.user-information .flex-wrap-row {
  display: flex;
  flexflow: row wrap;
}
