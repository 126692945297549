/* whenever these colors are updated, also update the colors in /src/config.colors */
:root {
	--primary-color: #234091;
	--secondary-color: #F4F6F8;
	--tertiary-color: #BD943F;
	--quaternary-color: lightgrey;
}

::placeholder {
	font-style: italic;	
}

.ant-select-selection__placeholder, .ant-select-search__field__placeholder {
	font-style: italic;
}

.padding-0 { padding: 0; }
.padding-0-5 { padding: 0.5rem; }
.padding-1 { padding: 1rem; }

.padding-top-1 { padding-top: 1rem; }
.padding-bottom-1 { padding-bottom: 1rem; }
.padding-left-1 { padding-left: 1rem; }
.padding-right-1 { padding-right: 1rem; }

.padding-left-right-1 {
	padding-left: 1rem;
	padding-right: 1rem;
}

.padding-2 { padding: 2rem; }

.padding-top-2 { padding-top: 2rem; }
.padding-bottom-2 { padding-bottom: 2rem; }
.padding-left-2 { padding-left: 2rem; }
.padding-right-2 { padding-right: 2rem; }

.padding-3 { padding: 3rem; }

.margin-0 { margin: 0; }
.margin-0-5 { margin: 0.5rem; }
.margin-1 { margin: 1rem; }

.margin-top-1 { margin-top: 1rem; }
.margin-bottom-1 { margin-bottom: 1rem; }
.margin-left-1 { margin-left: 1rem; }
.margin-right-1 { margin-right: 1rem; }

.margin-2 { margin: 2rem; }

.margin-top-2 { margin-top: 2rem; }
.margin-bottom-2 { margin-bottom: 2rem; }
.margin-left-2 { margin-left: 2rem; }
.margin-right-2 { margin-right: 2rem; }

.flex-row {
	display: flex;
	flex-flow: row nowrap;
}

.flex-wrap {
	display: flex;
	flex-flow: row wrap;
}

.flex-column {
	display: flex;
	flex-flow: column;
}

.align-center { align-items: center; }
.align-start { align-items: flex-start; }
.align-end { align-items: flex-end; }
.align-stretch { align-items: stretch; }

.justify-center { justify-content: center; }
.justify-start { justify-content: flex-start; }
.justify-end { justify-content: flex-end; }
.justify-space-between { justify-content: space-between }
.justify-space-around { justify-content: space-around; }

.full-width { width: 100%; }
.full-height { height: 100%; }

.bold { font-weight: bold; }
.italic { font-style: italic; }

.background-white { background: white; }
.color-white { color: white; }

.background-red { background: red; }
.color-red { color: red; }

.background-black { background: black }
.color-black { color: black }

.background-grey { background: grey }
.color-grey { color: grey }

.transition-padding-0-5 { transition: padding 0.5s; }
.transition-padding-1 { transition: padding 1s; }

.pointer { cursor: pointer }

.background-primary { background: var(--primary-color); }
.background-secondary { background: var(--secondary-color); }
.background-tertiary { background: var(--tertiary-color); }
.background-quaternary { background: var(--quaternary-color) }

.color-primary { color: var(--primary-color); }
.color-secondary { color: var(--secondary-color); }
.color-tertiary { color: var(--tertiary-color); }
.color-quaternary { color: var(--quaternary-color); }

.radius-1 { border-radius: 1rem; }
.radius-2 { border-radius: 1rem; }
.radius-3 { border-radius: 1rem; }

.font-size-1 { font-size: 1rem; }
.font-size-2 { font-size: 2rem; }

.z10 { z-index: 10 }
.z100 { z-index: 100 }
.z1000 { z-index: 1000 }