.padding-top-bottom-2 {
	padding: 2rem 0 2rem 0;
}

.padding-left-right-2 {
	padding: 0 2rem 0 2rem;
}

.margin-top-2 {
	margin: 2rem;
}

.height-full {
	height: 100%;
}

.border-circle {
	border-radius: 50%;
}

.overflow-scroll {
	overflow: scroll;
}

.schedule-inspections-container {
	text-align: left;
	background: white;
}

.schedule-inspections-header {
	padding: 2rem;
	margin: 0;
	border-bottom: 0.1rem solid #C4CDD5;
}

.schedule-inspections-body {
	height: 60rem;
	border-bottom: 0.1rem solid #C4CDD5;
}

.schedule-inspections-left-panel {
	flex: 2;
	border-right: 0.1rem solid #C4CDD5;
}

.schedule-inspections-right-panel {}

.schedule-inspections-button-panel {
	background: transparent;
	border: none;
	display: flex;
	justify-content: flex-end;
}

.user-popover-container {
	width: 50rem;
}

.user-popover-permissions {
	padding: 0.5rem 0.3rem 0.5rem 0.3rem;
	margin: 0 0.2rem 0 0;
	border: 0.1rem solid lightgray;
}

.user-brief-container {
	border-radius: 0 0 0.3rem 0.3rem;
	box-shadow: 0 0 0 1px rgba(63 63 63 0.05), 0 1px 3px 0 rgba(63, 63, 63, 0.15);
}

.project-brief-container {
	margin-bottom: 1rem;
	border-left: 0.3rem solid #912240;
	border-radius: 0.2rem;
	cursor: pointer;
	box-shadow: 0 0 0 1px rgba(6,44,82,0.1), 0 1px 3px 0 rgba(64,66,69,0.12), 0 2px 16px 0 rgba(33,43,54,0.08);
}

.projects-container {
	flex: 1;
	overflow: scroll;
	padding: 2rem 0 2rem 0;
}

.calendar-wrapper {
	margin: 1rem;
	border: 0.1rem solid #C4CDD5;
}

.project-popover-container {
	width: 30rem;
	overflow: scroll;
	height: 20rem;
}

.tiny-label {
	font-size: 75%;
	color: 'light-grey'
}