@import "../styles/colors";

.analytics-page {
  .page-title {
    margin: 2rem;
    font-weight: 600;
  }

  .table-description {
    margin-top: 4rem;
    font-weight: 600;
    font-size: 1.5rem;
    padding: 0.75rem 0;
    margin-bottom: 0;

    $mix-weight: 80;
    &.better {
      background-color: mix($lighter-blue, green, $mix-weight);
    }
    &.worse {
      background-color: mix($lighter-blue, red, $mix-weight);
    }
  }

  .selector {
    width: 100%;
    padding-right: 2rem;

    &.first {
      padding-left: 2rem;
    }
  }
}
