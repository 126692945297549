
.flex-row {
    display: flex;
    flex-flow: row nowrap;
}

.flex-column {
    display: flex;
    flex-flow: column;
}

.align-center {
    align-items: center;
}

.align-start {
    align-items: flex-start;
}

.justify-center {
    justify-content: center;
}

.filter-group-wrapper {

}

.filter-group-row-1 {}
.filter-group-row-2 {}

.table-header {}

.table-body-wrapper {}

.tab-filters-wrapper {
    position: relative;
    margin: 0.1rem 0 0.2rem 0;
}

.tab-filters-blocking-overlay {
    position: absolute;
    flex: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
}

.filter-bar-searchbar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 50;
    border: 0.1rem solid rgba(211, 211, 211, 0.6);
    /* border-left: 0; */
    border-radius: 0 0.2rem 0.2rem 0;
    padding: 1.5rem;
    height: 100%;
}

.filter-bar-save-filter-button {
    flex: 1;
    height: 4rem;
    margin: 0 0.5rem 0 0.5rem;
    background: green;
    color: white
}

.padding-1 {
    padding: 1rem;
}

.padding-2-top {
    padding-top: 2rem;
}

.padding-2-bottom {
    padding-bottom: 2rem;
}

.tab-filters-filter-field {
    padding: 0.5rem 2rem 0.75rem 2rem;
    margin: 0 0.3rem 0 0;
    font-size: 0.9vw;
    cursor: pointer;
}

.thin-lightgrey-border {
    border: 1px solid lightgrey;
}

.bold {
    font-weight: bold;
}

.full-height {
    height: 100%;
}