:root {
    --photo-pool-padding: 3rem;
}

.photo-pool:not(.modal) {
    position: sticky;
    top: 0;
    padding: var(--photo-pool-padding);
    background: white;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center; 
}

.photo-pool .button {
    margin: 1rem 1rem 2rem 0; 
    align-self: flex-start;
}

.photo-pool .horizontal-divider {
    width: calc(100% + 2 * var(--photo-pool-padding));
    height: 2px;
    margin: 1rem 0;
    background-color: #D9D9D9;
}