.header-container {
	padding: 0 2rem 0 0;
	justify-content: space-between;
}

.page-header {
	padding: 3rem 0 0 2rem;
	display: flex;
	align-self: flex-start;
	font-weight: bolder;
}

.flex-row {
	display: flex; 
	flex-flow: row nowrap;
}

.flex-column {
	display: flex; 
	flex-flow: column;	
}

.flex-align-center {
	align-items: center;
}

.flex-align-start {
	align-items: flex-start;	
}

.full-width {
	width: 100%;
}

.user-information-pane {
	flex: 3;
	padding: 2rem;
	background: white;
	margin: 2rem;
}

.user-information-pane-title {
	justify-content: space-between;
}

.user-information-pane-edit-button {
	cursor: pointer;
	padding: 1rem;
	border: 0.1rem solid lightgrey;
}

.address-wrapper {
	flex: 1;
	padding: 1.5rem 0 0.5rem 0;
	border-bottom: 0.1rem solid lightgrey;	
}

.address-title {
	color: grey;
	fontSize: 65%;
	text-align: left;
	padding: 0 0 0 0.2rem;
}

.avatar-pane-container {
	flex: 1;
	justify-content: center;
	margin: 2rem 2rem 2rem 0;
	padding: 1rem 2rem 0 2rem;
	background: white;
}

.avatar {
	border-radius: 50%;
	margin: 0 0 3rem 0;
}

.avatar-action-button {
	cursor: pointer;
	padding: 1rem;
	margin: 0.5rem;
	border: 0.1rem solid lightgrey;
}

.notify-button {
	padding: 2rem 0 0 0;
	font-weight: bold;
}

.info-text {
	color: grey; 
	font-size: 65%; 
	text-align: left; 
	padding: 0 0 0 0.2rem;
}

.field-wrapper {
	padding: 1.5rem 0 0.5rem 0;
	border-bottom: 0.1rem solid lightgrey;
}