.anticon {
    color: black;
}

.ant-table-thead > tr > th > i.anticon-filter {
    color: black;
}

.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on, .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on {
    color: orange;
}