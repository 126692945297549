.container {
	display: flex;
	flex-flow: column;
	padding: 1rem;
}

.title {
	font-weight: bolder;
	padding: 0.5rem 0.5rem 0.5rem 1rem;
}

.field {
	padding: 0.3rem 0.3rem 0.3rem 1.5rem;
}