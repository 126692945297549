.title-bar {
	border-bottom: 0.1rem solid #DFE3E8;
  	padding: 2rem;
	align-items: center;
	justify-content: space-between;
}

.status-bubble {
	background: #BD943F;
	color: white;
	border-radius: 1rem;
	padding: 0 1rem 0 1rem;
}

.rubric-container {
	padding: 0 0 1rem 0;
	margin: 2rem 2rem 0 2rem;
	justify-content: space-between;
	border-bottom: 0.1rem solid #DFE3E8;
}

.misc-info {
	border-bottom: 0.1rem solid #DFE3E8;
	margin: 0 1.5rem 0 1.5rem;
	padding: 1rem 0 1rem 0;
}

.misc-info-item {
	border: none;
	padding: 0.3rem;
}

.cancel-button {
	border-radius: 0.5rem;
	font-weight: 600;
	font-size: 18;
}

.toggle-installer-viewable {
	border-bottom: 0.1rem solid #DFE3E8;
	margin: 0 1.5rem 0 1.5rem;
	padding: 1rem 0 1rem 1rem;
	font-size: 115%;
	font-weight: bold;
}

.project-report {
	margin: 0 1.5rem 0 1.5rem;
	padding: 1rem 0 1rem 1rem;
	font-weight: bold;
	font-size: 115%;
	border-bottom: 0.1rem solid #DFE3E8;
}

.intake-link {
	margin: 0 1.5rem 0 1.5rem;
	padding: 1rem 0 1rem 1rem;
	font-size: 115%;
	font-weight: bold;
}

.snapshot-box {
	padding: 1rem;
	border-bottom: 0.1rem solid #DFE3E8;
	margin: 0 1.5rem 0 1.5rem;
}